import store from '@/store';


export default {
  inserted(el, binding, vnode) {
    console.log('值传过来了？',el, binding, vnode)

    let list = store.state.user.permissionList
    console.log(123,list)
    let bol;
    if(Array.isArray(binding.value)){
      bol = list.indexOf(binding.value[0]) != -1
    }else{
      bol = list.indexOf(binding.value) != -1
    }



    // 获取用户的权限码，假设从某个服务或全局状态中获取
    // const userPermissions = vnode.context.$store.state.user.permissions;
    //
    // // 获取指令绑定的值，即所需权限码
    // const requiredPermissions = binding.value;
    //
    // // 检查用户是否拥有所需权限
    // const hasPermission = userPermissions.some(permission => requiredPermissions.includes(permission));

    if (!bol) {
      // 如果没有权限，则移除该元素
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
};