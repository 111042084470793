// 1.导入Vue和Vuex
import Vue from 'vue'
import Vuex from 'vuex'
import user from "./user.js"
// 2.use Vuex
Vue.use(Vuex)
// 3.初始化Vue实例对象
const store = new Vuex.Store({
  modules: {
    user,
  }
})
// 4.暴露实例对象
export default store