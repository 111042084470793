import axios from 'axios';
import {
	Message
} from 'element-ui';
import router from '@/router';
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
let baseWebURL = '';
// 环境的切换
// if (process.env.NODE_ENV == 'development') { //开发环境
baseWebURL = process.env.VUE_APP_BASE_API; //多加一个api是后台那边统一拦截处理视项目情况而定加不加）
// baseWebURL = 'http://192.168.1.13/'; //ss多加一个api是后台那边统一拦截处理视项目情况而定加不加）
// } else if (process.env.NODE_ENV == 'test') { //测试环境
//   baseWebURL = 'http://39.98.112.95/';
// } else if (process.env.NODE_ENV == 'production') { //生产环境
//   baseWebURL = 'http://39.98.112.95/';
// }
//生成一个axios实例
const service = axios.create({
	baseURL: baseWebURL,
	//1.请求超时时间
	timeout: 15000
});
// console.log(service, 'service')
//3.公共部分(请求带token设置)
// service.defaults.headers.common['token'] = '1111111';
//4.返回数据类型的定义
// service.defaults.responseType = 'json';
//5.带cookie请求
// service.defaults.withCredentials = false

// 请求拦截器
service.interceptors.request.use(
	config => {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
		config.headers['source-application'] = 'com.rd.web';
		const token = localStorage.getItem('token')
		config.headers['token'] = token
		return config;
	}, error => {
		return Promise.reject(error);
	});

// 响应拦截器
service.interceptors.response.use(
	response => {
		// console.log(222,'掉了两次',response)
		// console.log(response, 'response响应拦截器')
		// 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
		// 否则的话抛出错误
		if (response.status === 200) {
			//   tryHideFullScreenLoading() //关闭loading
			// Message({
			//   showClose: true,
			//   message: '响应成功',
			//   type: 'success'
			// })

			if (response.data.code === 20001) {
				Message({
					showClose: true,
					message: response.data.msg,
					type: 'error'
				})
				router.replace({
					path: '/login',
					query: {
						redirect: router.currentRoute.fullPath
					}
				});
				return Promise.reject(response);
			} else if (response.data.code === 200) {
				return Promise.resolve(response.data);
			} else {
				Message({
					showClose: true,
					message: response.data.msg,
					type: 'error'
				})
				return Promise.reject(response);
			}

			// return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	error => {
		if (error.code == "ECONNABORTED") {
			console.log(33, '这里设置捕获了呀')
			Message({
				showClose: true,
				message: '请求超时，请刷新页面',
				type: 'error'
			})
		}
		return Promise.reject(error);
	}
	//    error => {
	//     console.log(error, 'error')
	//     console.log(error.response,'error.response');
	//     if (error.response.data.status) {
	//       console.log('后台错误码统一处理')
	//       switch (error.response.data.status) {
	//         // 401:未登录;未登录则跳转登录页面，并携带当前页面的路径;在登录成功后返回当前页面，这一步需要在登录页操作。
	//         case 20001:
	//           router.replace({
	//             path: '/login',
	//             query: {
	//               redirect: router.currentRoute.fullPath
	//             }
	//           });
	//           break;
	//         // 403:token过期;登录过期对用户进行提示;清除本地token和清空vuex中token对象;跳转登录页面
	//         case 403:
	//         //   Message({
	//         //     showClose: true,
	//         //     message: '登录过期，请重新登录',
	//         //     duration: 1000,
	//         //     type: 'warning'
	//         //   })
	//           //清除token
	//           localStorage.removeItem('userToken');
	//         //   store.commit('LOGIN_OUT', null);
	//           //跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
	//           setTimeout(() => {
	//             router.replace({
	//               path: '/login',
	//               query: {
	//                 redirect: router.currentRoute.fullPath
	//               }
	//             });
	//           }, 1000);
	//           break;
	//         //404请求不存在
	//         // case 404:
	//         //   Message({
	//         //     showClose: true,
	//         //     message: '网络请求不存在',
	//         //     duration: 1000,
	//         //     type: 'error'
	//         //   })
	//         //   break;
	//         //其他错误,直接抛出错误提示
	//         default:
	//           Message({
	//             showClose: true,
	//             message: error.response.data.message,
	//             duration: 1000,
	//             type: 'error'
	//           })
	//       }
	//     }
	//     return Promise.reject(error);
	//   }
);


// 封装axios的get请求
export function getData(url, params) {
	return service({
		url: url,
		method: 'GET',
		params
	});

	// return new Promise((resolve, reject) => {
	//   service.get(url, {params:params}).then(response => {
	//     resolve(response.data);
	//   })
	//     .catch(error => {
	//       reject(error);
	//     });
	// });
}
// 封装axios的post请求
export function postData(url, data) {
	return service({
		url: url,
		method: 'POST',
		data
	});
	// return new Promise((resolve, reject) => {
	//   service.post(url, params).then(response => {
	//     console.log(111,'掉了两次')
	//
	//
	//     resolve(response.data);
	//   })
	//     .catch(error => {
	//       reject(error);
	//     });
	// });
}