export default {
  namespaced: true,
  state: {
    permissionList:[],
  },
  mutations: {
    // 更新权限码
    updatePermission(state, list) {
      state.permissionList = list
      // 数据持久化存本地
      // this.commit('user/saveTokenToStorage')
    },
  },
  actions: {
    // 请求权限接口
    permissionFun(context) {
      let list = ['admin'];
      context.commit('updatePermission', list)
    }
  },
}